:root {
    --rct-color-tree-bg: #f6f8fa;
    --rct-color-tree-focus-outline: #0366d6;

    --rct-color-focustree-item-selected-bg: #e8eaed;
    --rct-color-focustree-item-selected-text: inherit;
    --rct-color-focustree-item-focused-border: #0366d6;
    --rct-color-focustree-item-draggingover-bg: #ebf0f4;
    --rct-color-focustree-item-draggingover-color: inherit;

    --rct-color-nonfocustree-item-selected-bg: #e8eaed;
    --rct-color-nonfocustree-item-selected-text: inherit;
    --rct-color-nonfocustree-item-focused-border: #dbdbdb;

    --rct-color-search-highlight-bg: #a2aed2;
    --rct-color-drag-between-line-bg: #0366d6;
    --rct-color-arrow: #373a3f;

    --rct-item-height: 22px;

    --rct-color-renaming-input-submitbutton-bg: inherit;
    --rct-color-renaming-input-submitbutton-bg-hover: #0366d6;
    --rct-color-renaming-input-submitbutton-bg-active: #095fc1;

    --rct-color-renaming-input-submitbutton-text: inherit;
    --rct-color-renaming-input-submitbutton-text-hover: #ffffff;
    --rct-color-renaming-input-submitbutton-text-active: #ffffff;

    /* VMT */
    --rct-color-tree-item-button: rgb(55, 206, 255);
    --rct-color-tree-item-button-isFolder: whitesmoke;
}

.rct-tree-root {
    /* font-family: sans-serif; */
    /* VMT */
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    font-family: 'Monospaced Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: var(--rct-color-tree-bg);
    padding: 4px 0;
}

.rct-tree-root-focus {
    outline: 1px solid var(--rct-color-tree-focus-outline);
}

.rct-tree-item-li {
    /* font-size: 0.8rem; */
    /* VMT */
    /* font-size: 1.0rem; */
    font-size: 14px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.rct-tree-item-title-container {
    display: flex;
    align-items: center;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.rct-tree-child-list {}

.rct-tree-item-button {
    flex-grow: 1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    border: none;
    width: 100%;
    height: var(--rct-item-height);
    text-align: left;
    color: inherit;
    /* VMT */
    color: var(--rct-color-tree-item-button);
    outline: none;
    /* VMT */
    white-space: nowrap;
}

/* VMT */
.rct-tree-item-button-isFolder {
    color: var(--rct-color-tree-item-button-isFolder);
}
/* 
.rct-tree-item-arrow {
    width: 10px;
}

.rct-tree-item-arrow svg {
    width: 10px;
} */

.rct-tree-item-arrow-path {
    fill: var(--rct-color-arrow);
}

.rct-tree-items-container {
    margin: 0;
    padding: 0;
}

.rct-tree-root:not(.rct-tree-root-focus) .rct-tree-item-title-container-selected {
    background-color: var(--rct-color-nonfocustree-item-selected-bg);
    color: var(--rct-color-nonfocustree-item-selected-text);
}

.rct-tree-root.rct-tree-root-focus .rct-tree-item-title-container-selected {
    background-color: var(--rct-color-focustree-item-selected-bg);
    color: var(--rct-color-focustree-item-selected-text);
}

.rct-tree-root.rct-tree-root-focus .rct-tree-item-title-container-focused {
    outline: none;
    border-color: var(--rct-color-focustree-item-focused-border);
}

.rct-tree-root:not(.rct-tree-root-focus) .rct-tree-item-title-container-focused {
    outline: none;
    border-color: var(--rct-color-nonfocustree-item-focused-border);
}

.rct-tree-item-title-container-dragging-over {
    background-color: var(--rct-color-focustree-item-draggingover-bg);
    color: var(--rct-color-focustree-item-draggingover-color);
}

.rct-tree-item-button-search-match {
    font-style: italic;
}

.rct-tree-item-search-highlight {
    background-color: var(--rct-color-search-highlight-bg);
}

.rct-tree-drag-between-line {
    position: absolute;
    right: 0;
    top: -2px;
    height: 4px;
    background-color: var(--rct-color-drag-between-line-bg);
}

.rct-tree-drag-between-line-top {
    top: 0px;
}

.rct-tree-drag-between-line-bottom {
    top: -4px;
}

.rct-tree-search-input-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
}

.rct-tree-search-input {
    position: fixed;
    width: 120px;
}

/* TODO see renaming form in multiple tree example, wraps around */
.rct-tree-item-renaming-form {
    flex-grow: 1;
    display: flex;
}

.rct-tree-item-renaming-input {
    flex-grow: 1;
    background-color: inherit;
    border: none;
    color: inherit;
    outline: none;
}

.rct-tree-item-renaming-submit-button {
    border: none;
    background-color: var(--rct-color-renaming-input-submitbutton-bg);
    border-radius: 999px;
    color: var(--rct-color-renaming-input-submitbutton-text);
    cursor: pointer;
}

.rct-tree-item-renaming-submit-button:hover {
    background-color: var(--rct-color-renaming-input-submitbutton-bg-hover);
    color: var(--rct-color-renaming-input-submitbutton-text-hover);
}

.rct-tree-item-renaming-submit-button:active {
    background-color: var(--rct-color-renaming-input-submitbutton-bg-active);
    color: var(--rct-color-renaming-input-submitbutton-text-active);
}