.split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-color: #9ca4b52a;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter:hover {
    background-color: #9ca4b569;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}

.button.gutter-horizontal {
    width: 10px;
    height: 70px;
    padding: 0px;
    border: 0px;
    color: lightgray;
    background-color: #585c5480;
    cursor: default;
}

.button.gutter-horizontal:hover {
    color: beige;
    background-color: #3c3c3c79;
}

.gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
    cursor: row-resize;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    /* background: #191b21; */
    overflow: hidden;
    font-family: 'Monospaced Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 21px;
}

html,
body,
div,
a,
i,
button,
select,
option,
optgroup,
hr,
br {
    user-select: none;
    cursor: default;
}

[data-theme='dark'] {
    background: #191b21;
}

[data-theme='light'] {
    background: #ffffff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #282c3436;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #504e4e6e;
    border-radius: 10px;
}

/* Corner */
::-webkit-scrollbar-corner {
    background: #ffffff00;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}