@import "./react-complex-tree-style.css";

:root {
  --rct-color-tree-bg: transparent;
  --rct-item-height: 28px;
  --rct-color-search-highlight-bg: #acccf1;

  --rct-color-tree-focus-outline: transparent;
  --rct-item-margin: 1px;
  /* --rct-item-padding: 8px; */
  --rct-item-padding: 2px;
  --rct-radius: 4px;
  --rct-bar-offset: 6px;
  --rct-bar-width: 4px;
  /* --rct-bar-color: #0366d6; */
  /* VMT */
  --rct-bar-color: #5e02027c;
  --rct-focus-outline: #000000;

  /* --rct-color-focustree-item-selected-bg: #f0f2f5; */
  /* VMT */
  --rct-color-focustree-item-selected-bg: #bbbbbb75;
  /* --rct-color-focustree-item-hover-bg: #f0f2f5; */
  /* VMT */
  --rct-color-focustree-item-hover-bg: #dddddd75;
  --rct-color-focustree-item-hover-text: inherit;
  --rct-color-focustree-item-active-bg: #e4e6eb;
  --rct-color-focustree-item-active-text: #4f4f4f;

  /* VMT */
  --rct-color-tree-item-button: rgb(135, 135, 135);
  --rct-color-tree-item-button-isFolder: #4e535a;

  --rct-arrow-size: 10px;
  --rct-arrow-container-size: 16px;
  --rct-arrow-padding: 6px;

  --rct-cursor: pointer;

  --rct-search-width: 120px;
  --rct-search-height: 16px;
  --rct-search-padding: 8px;
  --rct-search-border: #b4b7bd;
  --rct-search-border-bottom: #0366d6;
  --rct-search-bg: #f8f9fa;
  --rct-search-text: #000000;
  --rct-search-text-offset: calc(var(--rct-search-padding) * 2 + 16px);
}

.rct-dark {
  --rct-color-search-highlight-bg: #2f5381;

  --rct-bar-color: #1d7ae5c2;
  --rct-focus-outline: #ffffff;

  --rct-color-focustree-item-selected-text: #ffffff;
  --rct-color-focustree-item-selected-bg: #373737;
  --rct-color-focustree-item-hover-bg: #373737;
  --rct-color-focustree-item-hover-text: #ffffff;
  --rct-color-focustree-item-active-bg: #313131;
  --rct-color-focustree-item-active-text: #ffffff;
  --rct-color-focustree-item-draggingover-bg: #313131;
  --rct-color-focustree-item-draggingover-color: #ffffff;
  --rct-color-arrow: #ffffff;

  /* VMT */
  /* --rct-color-tree-item-button: rgb(55, 206, 255); */
  --rct-color-tree-item-button: rgb(185, 185, 185);
  --rct-color-tree-item-button-isFolder: whitesmoke;

  --rct-search-border: #4f4f4f;
  --rct-search-border-bottom: #1d7be5;
  --rct-search-bg: #373737;
  --rct-search-text: #ffffff;
  --rct-search-text-offset: calc(var(--rct-search-padding) * 2 + 16px);
}

.rct-tree-item-button {
  padding: 0 var(--rct-item-padding) 0 calc(var(--rct-item-padding) + var(--rct-arrow-container-size) + var(--rct-arrow-padding));
  margin-left: calc(-1 * var(--rct-arrow-size));
  cursor: var(--rct-cursor);
  transition: color 100ms ease-out, background-color 100ms ease-out;
  /* VMT */
  white-space: nowrap;
}

.rct-tree-item-button {
  margin-top: var(--rct-item-margin);
  margin-bottom: var(--rct-item-margin);
  position: relative;
  border-radius: var(--rct-radius);
}

.rct-tree-item-button:focus-visible {
  outline: 2px solid var(--rct-focus-outline);
}

.rct-tree-item-button:hover {
  background-color: var(--rct-color-focustree-item-hover-bg);
  /* VMT */
  /* color: var(--rct-color-focustree-item-hover-text); */
}

/* VMT */
.rct-tree-item-button.d-n-d-hover {
  background-color: var(--rct-color-focustree-item-hover-bg);
}

/* VMT */
.rtc-tree-icon {
  padding-right: calc(2*var(--rct-item-padding));
  padding-bottom: var(--rct-item-padding);
}

.rct-tree-item-button:active {
  background-color: var(--rct-color-focustree-item-active-bg);
  /* VMT */
  /* color: var(--rct-color-focustree-item-active-text); */
}

.rct-tree-item-title-container-selected .rct-tree-item-button {
  background-color: var(--rct-color-focustree-item-selected-bg);
  /* VMT */
  /* color: var(--rct-color-focustree-item-selected-text); */
}

.rct-tree-item-title-container-selected .rct-tree-item-button::before {
  content: " ";
  position: absolute;
  top: calc(var(--rct-bar-offset) + var(var(--rct-item-margin)));
  /* left: calc(-1 * var(--rct-bar-width));*/
  left: calc(-0.5 * var(--rct-bar-width));
  height: calc(var(--rct-item-height) - 2 * var(--rct-bar-offset));
  width: var(--rct-bar-width);
  background-color: var(--rct-bar-color);
  border-radius: 99px;
}

.rct-tree-item-title-container-dragging-over .rct-tree-item-button {
  background-color: var(--rct-color-focustree-item-draggingover-bg);
  color: var(--rct-color-focustree-item-draggingover-color);
}

.rct-tree-item-title-container {
  border: none;
  background-color: unset !important;
}

.rct-tree-item-arrow {
  z-index: 1;
  margin-right: calc(-1 * var(--rct-arrow-container-size) + var(--rct-arrow-padding));
  width: var(--rct-arrow-container-size);
  height: var(--rct-arrow-container-size);
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: var(--rct-radius);
  cursor: var(--rct-cursor);
}

.rct-tree-item-arrow svg {
  /* width: var(--rct-arrow-size); */
  align-self: center;
}

.rct-tree-item-arrow.rct-tree-item-arrow-isFolder:hover {
  background-color: var(--rct-color-focustree-item-hover-bg);
  color: var(--rct-color-focustree-item-hover-text);
}

/* VMT */
.rct-tree-item-arrow.rct-tree-item-arrow-isFolder.d-n-d-hover {
  background-color: var(--rct-color-focustree-item-hover-bg);
  color: var(--rct-color-focustree-item-hover-text);
}


.rct-tree-item-renaming-submit-button {
  border-radius: var(--rct-radius);
  visibility: hidden;
}

.rct-tree-drag-between-line {
  border-radius: 99px;
  height: 3px;
}

.rct-tree-search-input-container {
  width: calc(var(--rct-search-width) + var(--rct-search-text-offset) + var(--rct-search-padding) + 2px);
}

.rct-tree-search-input {
  width: var(--rct-search-width);
  height: var(--rct-search-height);
  padding: var(--rct-search-padding);
  padding-left: var(--rct-search-text-offset);
  margin: 0;
  border: 1px solid var(--rct-search-border);
  border-bottom: 2px solid var(--rct-search-border-bottom);
  border-radius: var(--rct-radius);
  background-color: var(--rct-search-bg);
  color: var(--rct-search-text);
}

.rct-tree-search-input:focus {
  outline: none;
}

.rct-tree-search-input-container::before {
  content: url(data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22currentColor%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%2016%2016%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.742%2010.344a6.5%206.5%200%201%200-1.397%201.398h-.001c.03.04.062.078.098.115l3.85%203.85a1%201%200%200%200%201.415-1.414l-3.85-3.85a1.007%201.007%200%200%200-.115-.1zM12%206.5a5.5%205.5%200%201%201-11%200%205.5%205.5%200%200%201%2011%200z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
  position: absolute;
  top: calc(var(--rct-search-height) / 2);
  left: var(--rct-search-padding);
  z-index: 1;
}

.rct-dark .rct-tree-search-input-container::before {
  content: url(data:image/svg+xml,%3Csvg%20stroke%3D%22%23ffffff%22%20fill%3D%22%23ffffff%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%2016%2016%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.742%2010.344a6.5%206.5%200%201%200-1.397%201.398h-.001c.03.04.062.078.098.115l3.85%203.85a1%201%200%200%200%201.415-1.414l-3.85-3.85a1.007%201.007%200%200%200-.115-.1zM12%206.5a5.5%205.5%200%201%201-11%200%205.5%205.5%200%200%201%2011%200z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
}